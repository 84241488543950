.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.title {
    color: var(--colorText, #344054);
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.5px;
}

.info {
    margin-bottom: 40px;
    color: var(--colorTextSecondary, #667085);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.spinner {

}