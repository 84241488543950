.container {
    display: grid;
    grid-template-columns: 2fr auto;
    gap: 20px;
    margin-bottom: 10px;
    align-items: center;
}

.icon{
    width: 20px;
    height: 20px;
}

.icon path{
    fill: #AEB2BA;
}