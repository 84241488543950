.container{
    align-content: start;
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 18px 0 rgba(147, 149, 153, 0.10);
}

.content{
    display: flex;
}

.name{
    color: var(--colorText, #344054);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.location{
    display: flex;
    align-items: center;
}