.Sidebar.Sidebar {
  display: flex;
  grid-area: sidebar;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-right: 1px solid #eaedf2; /* divider color */
  z-index: 11;
}

.Sidebar.SidebarColapsed {
  /*width: 500px*/
}

.HeroLogo {
  padding: 2px 9px;
  cursor: pointer;
}

.Navigation {
  display: flex;
  flex-direction: column;
}

.NavLink {
  width: 100%;
  height: 48px;
  color: var(--black-dar-color);
  padding: 12px 12px 12px 10px;
  border-left: 2px solid transparent;
  cursor: pointer;
  overflow-x: hidden;
  display: flex;
  align-items: center;

}

.NavLink > svg {
  min-width: 24px;
  height: 24px;
}

.NavLink > span {
  margin-left: 12px;
}

.NavLink:hover {
  background-color: #F5F9FF; /* Blue/LightBackground */
  border-radius: 0 8px 8px 0;
}

.NavLinkCollapsed {
  width: calc(100% - 20px);
}

.NavLinkActive {
  background-color: #F5F9FF; /* Blue/LightBackground */
  border-radius: 0 8px 8px 0;
  border-left: 2px solid #0b7cda; /* colorPrimary */
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.logoContainer > .logo {
  margin-left: 12px;
  height: 24px;

}

.footer {
  max-width: 90%;
}

.logoutBtn {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 80%;
  display: flex;
  align-items: center;
}

.userInfo {
  display: flex;
}

.info  {
  font-size: 13px;
  width: 65%;
  margin-left: 12px;

}

.info p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
