p {
    margin: 0;
}

.company-short-desc {
    margin-top: 16px;
    color: #FFF;
    font-family: Montserrat, 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.container {
    display: flex;
    position: relative;
    gap: 24px;
}

.company-image-container {
    width: 96px;
    height: 96px;
    border-radius: 8px;
}

.company-image-container img,
.contact-icon-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.company-name {
    width: 80%;
    color: white;
    font-family: Montserrat, 'sans-serif';
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
}

.company-contacts {
    display: flex;
}

.contacts-main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 32px;
    position: relative;
}

.contact {
    display: inherit;
    align-items: center;
}

.contact:first-child {
    margin-right: 16px;
}

.contact-icon-wrapper {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.contact a{
    color: white;
    font-family: Montserrat, 'sans-serif';
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.short_description {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: white;
}