@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

:root {
  --primary-color: #0b7cda;
  --green-default-color: #00a369;
  --yellow-default-color: #eba000;
  --red-default-color: #e41a1a;
  --light-gray: #EAEDF2;
  --black-dar-color: #344054;
  --black-dark-grey-color: #667085;

  --colorPrimary: var(--primary-color);
  --colorInfo: var(--primary-color);
  --colorSuccess: var(--green-default-color);
  --colorWarning: var(--yellow-default-color);
  --colorError: var(--red-default-color);
  --colorText: var(--black-dar-color);
  --colorTextSecondary: var(--black-dark-grey-color);
}

body {
  margin: 0;
  font-family: 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.check-mark .ant-checkbox-inner {
  border-radius: 50%;
  pointer-events: none;
}

.position-container {
  padding-bottom: 104px;
  padding-top: 54px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 90px);
  max-width: 1264px;
}

.edit-container {
  padding-bottom: 104px;
  padding-top: 64px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 90px);
  max-width: 692px;
}

.text-reflector-details-title {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 16px;
  text-align: start;
}

.text-reflector-details-text {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: start;
  color: var(--black-dark-grey-color);
}


.gm-style-iw-t .gm-style-iw.gm-style-iw-c {
  padding: 10px !important;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid  #344054;
  background: #FFF;
  box-shadow: 4px 4px 0 0 #5498FE;
  width: fit-content !important;
}

.gm-style-iw-t .gm-style-iw.gm-style-iw-c > .gm-style-iw-d {
 overflow: hidden !important;
}

.gm-style-iw-tc::after, .gm-ui-hover-effect {
  display: none !important;
}
.ant-table-thead tr th,
.ant-table-thead tr td{
  background-color: #fff !important;
}


.ant-layout-sider-children {
  width: inherit;
}


.ant-layout-sider-children {
  width: inherit;
}