.BusinessesCard {
  border-radius: 0;
}

.BusinessesCard:first-child {
  border-radius: 4px 4px 0 0;
}

.BusinessesCard:last-child {
  border-radius: 0 0 4px 4px;
}
