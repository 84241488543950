.titleBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.companyAnalytics{
    padding-bottom: 104px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 90px);
    max-width: 1264px;
}

.header{
    color: #344054;
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.title{
    color: #344054;
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}