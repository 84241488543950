.container:not(:last-child) {
    margin-bottom: 24px;
}

.switch-container  {
    display: grid;
    gap: 16px;
}

.switch {
    display: flex;
    align-items: center;
    gap: 10px;
}
