.Layout {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template:
    'sidebar header'
    'sidebar content'
    'sidebar footer';
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto min-content;
  background-color: white;
}

.Content.Content.Content {
  position: relative;
  grid-area: content;
  width: 100%;
  max-height: 100vh;
  padding-inline: 36px;
  background-color: white;
  overflow-y: auto;
}

.Footer {
  grid-area: footer;
  padding: 20px 0;
  margin: 0 auto;
  display: grid;
  gap: 32px;
}
