.title,
.description {
    margin: 0;
}

.container {
    margin-top: 24px;
    margin-bottom: 40px;
}

.card-header {
    margin-bottom: 50px;
}

.card-header .title {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
}

.job-items {
    display: flex;
    justify-content: center;
}

.job-items .item:not(:last-child) {
    margin-right: 24px;
}

.job-items .item {
    display: inherit;
    align-items: center;
}

.item .description {
    font-size: 14px;
    font-weight: 500;
    margin-left: 6px;
}

.split-container {
    display: flex;
    gap: 24px;
    margin-bottom: 40px;
}

.split-container > div {
    width: 50%;
}

.consistent-container > :first-child {
    margin-bottom: 24px;
}

/*.message {*/
/*    font-size: 15px;*/
/*    font-weight: 600;*/
/*    line-height: 24px;*/
/*    margin-bottom: 20px;*/
/*}*/