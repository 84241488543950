.modal {
    width: 472px !important;

}

.modal :not(:last-child) {
    margin-bottom: 20px;
}

.modal  button {
    width: 100%;
}