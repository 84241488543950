.analyticsContainer {
    padding-bottom: 104px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 90px);
    max-width: 1264px;
}

.layout {
    min-height: 100vh;
}

.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dynamic_content{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 40px 10px;
}

.ant-menu-item-active{
    background-color: red;
}

.ant-menu-item-selected {
    background-color: #00a369;
}

.ant-menu-item-selected a {
    font-weight: bold;
    color: white;
}

.insight{
    color: #344054;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}