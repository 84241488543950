.container {
    height: 486px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.blue {
    background: conic-gradient(from 198deg at 60.51% 68.21%, #3253DC 0deg, #552BCE 360deg), lightgray 50% / cover no-repeat;
}

.orange {
    background: conic-gradient(from 198deg at 60.51% 68.21%, #E67B2E 0deg, #D9CF71 360deg), lightgray 50% / cover no-repeat;
}

.violet {
    background: conic-gradient(from 198deg at 60.51% 68.21%, #7834BD 0deg, #5654D7 360deg), lightgray 50% / cover no-repeat;
}

.green {
    background: conic-gradient(from 198deg at 60.51% 68.21%, #46C679 0deg, #13783B 360deg), lightgray 50% / cover no-repeat;
}

.purple{
    background: #7439C1;
}

.salad{
    background: #41BD72;
}