.title{
    font-family: 'Montserrat', sans-serif;
    color: #667085;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.header {
    font-family: 'Montserrat', sans-serif;
    margin-top: 8px;
    margin-bottom: 24px;
    color: #344054;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.editItem {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.editItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.editLocation {
    display: flex;
    width: 32%;
    flex-direction: column;
    gap: 10px;
}

.inputField {
    border-radius: 0;
    border-bottom: 1px solid var(--light-gray);
}