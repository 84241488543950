.map {
    height: 273px;
}

.marker-wrapper {
    width: 20px;
    height: 25px;
    margin-right: 10px;
}

.marker {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.address {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 160% */
}

.window {
    display: flex;
    align-items: center;
}