.LabelWithIcon {
  display: flex;
  align-items: center;
  gap: 4px;
}

/*TODO*/
.LabelWithIcon.limited{
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-icon{
  width: 15px;
  height: 15px;
}

.dropdown-icon path{
  fill: #344054;
}
