.container {
    display: flex;
    justify-content: flex-end;
}

.container.last {
    justify-content: space-between;
}

.container.first {
    justify-content: flex-end;
}


.manual {
    margin-right: 8px;
}