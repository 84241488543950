.skill-title {
    margin: 0;
}

.items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 40px;
}

.skill {
    text-align: left;
    width: fit-content;
    background-color: var(--light-gray);
    padding: 10px;
    border: 1px solid #EAEDF2;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}