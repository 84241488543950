.container{
    display: flex;
    width: 300px;
    height: 131px;
    padding: 24px 32px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #EAEDF2;
    background: #988CF7;
}

.header{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.text{
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}